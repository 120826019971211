@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__input-time-container .react-datepicker-time__caption {
    @apply hidden;
}

.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input {
    @apply w-full m-0;
}

.react-datepicker__input-time-container {
    @apply flex items-center justify-center w-full mx-0;
}

.react-datepicker__header {
    @apply bg-gray-lighter;
}

.react-datepicker__day--selected {
    @apply bg-primary text-white;
}

.fc .fc-timegrid-slot-label-cushion,
.fc .fc-timegrid-axis-cushion {
    @apply text-gray-dark text-sm;
}

.fc-day-today,
.fc .fc-daygrid-day.fc-day-today {
    @apply bg-gray-lighter;
}

#date-range-picker {
    display: none;
}

#date-range-picker-wrapper {
    height: 0;
}

#date-range-picker-wrapper > .react-datepicker-wrapper {
    height: 0;
    line-height: 0;
    background-color: red;
}

.react-datepicker-wrapper {
    @apply h-[40px];
}

input::placeholder {
    font-size: 11px;
    opacity: 0.8;
}

/* Firefox */

* {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #FFFFFF22;
    border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
    background-color: #ffffff33;
    border-radius: 8px;
    border: 1px solid #ffffff33;
}

